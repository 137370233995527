import { useState, useMemo } from 'react';
import { useLookUpItems } from './useLookUpItems';

const useFilter = (initialData: any, filtersConfig: any) => {
  const [filters, setFilters] = useState(filtersConfig);
  const { selectedStyles, setSelectedStyles, setItems } = useLookUpItems();

  const filteredData = useMemo(() => {
    return initialData.filter((item: any) =>
      Object.entries(filters).every(([key, filterValue]) => {
        // If the filter is not set, show all items
        if (filterValue === null || filterValue === undefined) return true;

        // Checking for strings or arrays that are empty
        if ((typeof filterValue === 'string' || Array.isArray(filterValue)) && filterValue.length === 0) return true;

        // Custom filtering logic based on the item's key and filter value
        if (typeof filterValue === 'string') {
          return item[key]?.toLowerCase().includes(filterValue.toLowerCase());
        }

        // This is where we adjust for the style filter being an array of strings
        if (key === 'style' && Array.isArray(filterValue)) {
          // Assuming the style property on the item is a single string,
          // check if the filterValue array includes the item's style.
          // This allows for filtering by multiple selected styles.
          return filterValue.includes(item[key]);
        }
        // Assuming filterValue can be an array of { value: string; label: string; }
        if (Array.isArray(filterValue)) {
          return filterValue.some(option => item[key]?.toLowerCase() === option.value.toLowerCase());
        }

        // Add more conditions for other data types if needed
        return item[key] === filterValue;
      })
    );
  }, [initialData, filters]);

  const setFilter = (key: any, value: any) => {
    setFilters((prev: any) => ({ ...prev, [key]: value }));
  };

  const clearFilters = () => {
    setFilters(filtersConfig);

  };

  return { filteredData, setFilter, clearFilters };
};


export default useFilter;
