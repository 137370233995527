import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header';
// import SearchBar from './components/SearchBar';
// import BalanceSheet from './components/BalanceSheet'
// import backgroundImageUrl from './components/images/homepg.jpg';
import '@aws-amplify/ui-react/styles.css';
import { Card, Heading, Text, Theme, ThemeProvider, useAuthenticator } from '@aws-amplify/ui-react';
import CustomerInfo from './components/CustomerInfo';
import { SearchProvider } from './components/hooks/SearchProvider';
import CustomerSearch from './components/CustomerSearch';
import { CustomerProvider } from './components/hooks/useCustomer';
import { BalanceSheetProvider } from './components/hooks/useBalanceSheet';
import { InventoryProvider } from './components/hooks/useInventory';
import { Customer } from './components/types/customer_interface';
import { fetchCustomer } from './api/axios_request';
import TabView from './components/TabView';
import { LookUpItemsProvider } from './components/hooks/useLookUpItems';
import { Authenticator, Button, useTheme, View, withAuthenticator, WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { signOut, fetchUserAttributes } from 'aws-amplify/auth';
import { UserProvider } from './components/hooks/useUserContext';
import { NotificationProvider } from './components/hooks/useNotification';



const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large} style={{ 
        backgroundColor: "#ffffff", // Assuming a white or light background to match the image
        borderBottom: '1px solid #ddd', // Adds a subtle border for definition, if needed
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow to give depth
        padding: '20px 40px', // Adjust padding to suit the layout
      }}>
        <h1 style={{
          color: "#2c3e50", // A dark shade that would stand out on a light background
          letterSpacing: "0.05em", // Fine-tuned letter-spacing
          lineHeight: "1.2", // Line height for better readability
          fontSize: "2.5rem", // Adjusted font size for balance
          fontWeight: "700", // Bold font weight
          textTransform: "uppercase", // Uppercase for a formal appearance
          fontFamily: "'Segoe UI', 'Helvetica Neue', Arial, sans-serif", // Font stack for cross-platform consistency
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)" // Text shadow for a slight 3D effect
        }}>
        Northdale Medical Supplies
        </h1>
      </View>
    );
  },
};

const customTheme = {
  name: 'my-custom-theme',
  tokens: {
    radii: {
      full: '9999px', // fully rounded for pill-like shapes
      button: '20px', // moderately rounded corners for buttons
    },
    colors: {
      brand: {
        primary: {
          10: '#E6F4FF', // lightest blue, for hover states on buttons
          60: '#0073E6', // vibrant blue for button backgrounds
          80: '#005BBB', // darker blue for active button states
          100: '#004495', // darkest blue, used for headings
        },
        neutral: {
          10: '#FAFAFA', // very light grey, for card backgrounds
          80: '#333333', // dark grey for text
          90: '#111111', // almost black for important text
        },
      },
      background: {
        primary: '#FFFFFF', // white background for cards
        secondary: '#EFEFEF', // light grey for page background
      },
      text: {
        primary: '#333333', // dark grey for primary text
        secondary: '#555555', // medium grey for secondary text
      },
    },
    components: {
      authenticator: {
        form: {
          padding: '2rem', // Spacious padding for the form
        },
      },
      card: {
        borderRadius: '{radii.full}', // Fully rounded corners for card elements
        backgroundColor: '{colors.background.primary}', // White background for cards
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)', // Soft shadow for cards
      },
      button: {
        borderRadius: '{radii.button}', // Moderately rounded corners for buttons
        primary: {
          backgroundColor: '{colors.brand.primary.60}', // Vibrant blue for buttons
          color: '{colors.white}', // White text on buttons
          _hover: {
            backgroundColor: '{colors.brand.primary.10}', // Light blue on hover
          },
          _active: {
            backgroundColor: '{colors.brand.primary.80}', // Dark blue on active
          },
        },
      },
      heading: {
        color: '{colors.brand.primary.100}', // Dark blue for headings
        fontWeight: '600', // Semi-bold for better readability
      },
      input: {
        borderRadius: '{radii.full}', // Fully rounded corners for input fields
        borderColor: '{colors.brand.primary.60}', // Vibrant blue border for inputs
        _focus: {
          borderColor: '{colors.brand.primary.80}', // Darker blue for input focus state
        },
      },
    },
  },
};

function App() {
  // const { authStatus } = useAuthenticator();
  // const showBackground = authStatus !== 'authenticated';
  // const { authStatus, user } = useAuthenticator();

  // // Optional: Perform any action right after checking the auth status
  // useEffect(() => {
  //   if (authStatus === 'authenticated') {
  //     console.log('User is authenticated', user);
  //     // Here you can set the user in your application's state or context
  //     // For example: setUser(user);
  //   }
  // }, [authStatus, user]);

  const fetchCustomers = async (term: string): Promise<Customer[]> => {
    const response = await fetchCustomer(term);
    // const data = await response.json();
    return response;
  };


  return (
    <ThemeProvider theme={customTheme}> {/* Pass the customTheme here */}
       
    {/* <div className={showBackground ? 'auth-background' : ''}> */}
      <Authenticator components={components} className="authenticator-container">
      <UserProvider>
                    <CustomerProvider>
            <div className="flex flex-col bg-gray-100 min-h-screen">
      
              <Header />
                <div className="flex-grow">
                  <div className="mx-auto px-3 lg:px-6">
                    <div className="grid grid-cols-1 lg:grid-cols-5 gap-4"> {/* Adjust to 5 columns for finer control */}
                      
                      {/* Left side components */}
                      <div className="lg:col-span-1 bg-white p-4 overflow-y-auto"> 
                        <SearchProvider<Customer> fetchSearchResults={fetchCustomers}>
                        <CustomerSearch/>
                        </SearchProvider>
                        <CustomerInfo />
                      </div>

                      {/* Right side component */}
                      <div className="lg:col-start-2 lg:col-span-4 bg-white p-4 h-screen overflow-y-auto" style={{ height: 'calc(100vh - 60px)' }}> {/* Span remaining columns */}
                      <NotificationProvider>
                        <InventoryProvider>
                        <LookUpItemsProvider>
                        <BalanceSheetProvider>
                        <TabView />
                        {/* <BalanceSheet /> */}
                        </BalanceSheetProvider>
                        </LookUpItemsProvider>
                        </InventoryProvider>
                      </NotificationProvider>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </CustomerProvider>
          {/* </> */}
          </UserProvider>
        {/* )} */}
      </Authenticator>
   
      {/* </div> */}
    </ThemeProvider>   
  );
}

export default App;

