import React from 'react';
// import { ReactComponent as CustomerIcon } from './icons/customer-icon.svg'; // Path to your icon

const NoCustomerPlaceholder: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-64"> {/* Adjust height as needed */}
      {/* <CustomerIcon className="w-16 h-16 mb-4 text-gray-400" /> TailwindCSS for styling; adjust as needed */}

      <h2 className="text-lg font-semibold text-gray-600 mb-2">
        Choose a Customer
      </h2>
      <p className="text-center text-gray-500">
        Lookup by the phone number. Select a customer to view and manage their balance sheet.
      </p>
    </div>
  );
};

export default NoCustomerPlaceholder;
