// DynamicTable.tsx
import React from 'react';

interface DynamicTableProps {
  headers?: string[];
  data: Record<string, any>[];
  showIndex?: boolean; // Optional index column
}

const DynamicTable: React.FC<DynamicTableProps> = ({ headers, data }) => {
  return (
    <div className="overflow-x-auto">
    <table className="min-w-full leading-normal">
      <thead>
        <tr>
          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
            ID
          </th>
          {headers?.map((header, index) => (
            <th
              key={index}
              className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider whitespace-nowrap"
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
              {data.length - rowIndex} {/* This will start the count from 1 for the last item */}
            </td>
            {Object.values(row).map((cell, cellIndex) => (
              <td
                key={cellIndex}
                className="px-5 py-3 border-b border-gray-200 bg-white text-sm whitespace-nowrap"
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
    {data.length === 0 && <div className="text-center py-3">No data available</div>}
  </div>
  );
};

export default DynamicTable;
