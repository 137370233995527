
const config = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID, // Adjusted to match your .env variable names
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID, // Adjusted to match your .env variable names
  },
},
};

export default config;
