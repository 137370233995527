import React, { useState } from 'react';
import BalanceSheetTable from './balance_sheet/BalanceSheetTable';
import AddRowForm from './balance_sheet/AddRowForm';
import { useBalanceSheet } from './hooks/useBalanceSheet';
import { useCustomer } from './hooks/useCustomer';
import NoCustomerPlaceholder from './NoCustomerPlaceholder';
import { NotesProvider } from './hooks/useNotesContext';


// interface BalanceSheetProps {
//   balanceData: any[]; // Replace with your balance data type
// }

// const BalanceSheet: React.FC<BalanceSheetProps> = ({ balanceData }) => {
const BalanceSheet:  React.FC = () => {
  const {customer} = useCustomer()
  const isCustomerChosen = Boolean(customer?.reference_id);

  return (
    <div className="overflow-x-auto">
    {isCustomerChosen ? (
      <>
        {/* <AddRowForm /> */}
        <NotesProvider>
          <BalanceSheetTable />
        </NotesProvider>
      </>
    ) : (
      <NoCustomerPlaceholder /> // Display the placeholder when no customer is chosen
    )}
  </div>
  );
};

export default BalanceSheet;
