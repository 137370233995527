// NotesContext.tsx
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { fetchNotes, submitNotes } from '../../api/axios_request';
import { useCustomer } from './useCustomer';
import { useNotification } from './useNotification';

interface NotesContextType {
  notes: string;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
  loadNotes: () => void;
  // handleSubmitNotes: () => void;
  handleSubmitNotes: (latestNotes: string) => Promise<void>; // Adjust type definition

}

const NotesContext = createContext<NotesContextType | undefined>(undefined);

export const useNotes = () => {
  const context = useContext(NotesContext);
  if (!context) {
    throw new Error('useNotes must be used within a NotesProvider');
  }
  return context;
};

export const NotesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notes, setNotes] = useState<string>('');
  const { customer } = useCustomer();
  const { showNotification } = useNotification();
  const reference_id = customer?.reference_id || '';

  const loadNotes = useCallback(async () => {
    if (!reference_id) {
      return;
    }
    try {
      const response = await fetchNotes(reference_id);
      if (response.data && response.data.length > 0) {
        setNotes(response.data[0].notes || ''); // Adjust based on actual response structure
      } else {
        setNotes(''); // Set to empty string if no notes are found
      }
    } catch (error) {
      console.error('Failed to fetch notes:', error);
      showNotification('Error fetching notes.', 'error');
    }
  }, [reference_id, showNotification]);


  useEffect(() => {
    if (reference_id) {
      loadNotes();
    }
  }, [reference_id]);


  const handleSubmitNotes = useCallback(async (latestNotes: string) => {
    if (!reference_id || !latestNotes) {
      showNotification('Cannot submit notes: reference ID or notes are missing.', 'error');
      return;
    }
    try {
      const response = await submitNotes({ reference_id, notes: latestNotes });
      // console.log('API response:', response); // Log the API response

      if (response === true) {
        showNotification('Notes successfully submitted!', 'success');
        setNotes(latestNotes); // Update notes with the latest submitted value
      } else {
        showNotification('Error submitting notes in handleSubmit in else clause.', 'error');
      }
    } catch (error) {
      console.error('Failed to submit notes:', error);
      showNotification('Error submitting notes.', 'error');
    }
  }, [reference_id, showNotification]);


  return (
    <NotesContext.Provider value={{ notes, setNotes, handleSubmitNotes, loadNotes }}>
      {children}
    </NotesContext.Provider>
  );
};
