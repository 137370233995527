import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of the context
interface NotificationContextType {
  showNotification: (message: string, type: 'success' | 'error') => void;
}

// Initial context value with types
const initialContextValue: NotificationContextType = {
  showNotification: () => {}, // Placeholder function
};

const NotificationContext = createContext<NotificationContextType>(initialContextValue);

// Props type for NotificationProvider
interface NotificationProviderProps {
  children: ReactNode; // Correct type for children
}

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [notification, setNotification] = useState({
    visible: false,
    message: '',
    type: '', // Consider making this 'success' | 'error' to enforce correct usage
  });

  const showNotification = (message: string, type: 'success' | 'error') => {
    setNotification({ visible: true, message, type });

    setTimeout(() => {
      setNotification({ visible: false, message: '', type: '' });
    }, 5000); // Auto-hide after 5 seconds
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      {notification.visible && (
        <div className={`fixed top-5 right-5 p-4 rounded-md shadow-lg text-white ${notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'} transition-opacity duration-300`} role="alert">
          {notification.message}
        </div>
      )}
    </NotificationContext.Provider>
  );
};
