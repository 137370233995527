// CustomerSearchComponent.tsx

import { fetchCustomer } from '../api/axios_request';
import { SearchProvider } from '../components/hooks/SearchProvider';
import CustomerList from './CustomerList'; // Assume you have a component to list customers
import NoCustomerPlaceholder from './NoCustomerPlaceholder';
import SearchBar from './SearchBar';
import { useCustomer } from './hooks/useCustomer';
import { Customer } from './types/customer_interface'; // Assume you have defined a Customer type
import { useSearch } from './hooks/SearchContext';

const CustomerSearch: React.FC = () => {
  const { searched_items } = useSearch<Customer>(); // Use the generic type with your Customer type
  const { customer } = useCustomer(); // Access the customer from the global state

  // const { setCustomer } = useCustomer(); // Access the customer from the global state
  const isCustomerChosen = Boolean(searched_items.length > 0 || customer); // Adjust based on how customer presence is determined
  // console.log(searched_items)

  const fetchCustomers = async (term: string): Promise<Customer[]> => {
    const response = await fetchCustomer(term);
    // const data = await response.json();
    return response;
  };

  return (
    // <SearchProvider<Customer> fetchSearchResults={fetchCustomers}>
    <>
    {isCustomerChosen ? (
          <>
            <SearchBar />
            <CustomerList />
          </>
        ) : (
          <>
          <SearchBar />
          <NoCustomerPlaceholder /> 
          </>
    )}
      {/* <SearchBar />
      <CustomerList /> */}
    {/* // </SearchProvider> */}
    </>
  );
};

export default CustomerSearch;