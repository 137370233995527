// SearchProvider.tsx

import React, { useState } from 'react';
import { SearchContext } from './SearchContext';

interface SearchProviderProps<T> {
  children: React.ReactNode;
  fetchSearchResults: (term: string) => Promise<T[]>;
}

export function SearchProvider<T>({ children, fetchSearchResults }: SearchProviderProps<T>) {

  const [searchTerm, setSearchTerm] = useState('');
  const [searched_items, setItems] = useState<T[]>([]);
  const [selectedItem, setSelectedItem] = useState<T | null>(null);

  const handleSearch = async (term: string) => {
    try {
      const results = await fetchSearchResults(term);
      setItems(results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <SearchContext.Provider value={{
      searchTerm,
      setSearchTerm,
      handleSearch,
      searched_items,
      setItems,
      selectedItem,
      setSelectedItem
    }}>
      {children}
    </SearchContext.Provider>
  );
}