// NotesModal.tsx
import React, { useState, useEffect } from 'react';

interface NotesModalProps {
  isOpen: boolean;
  notes: string;
  onClose: () => void;
  onSave: (updatedNotes: string) => void;
}

const NotesModal: React.FC<NotesModalProps> = ({ isOpen, notes, onClose, onSave }) => {
  const [editedNotes, setEditedNotes] = useState(notes);

  useEffect(() => {
    if (isOpen) {
      setEditedNotes(notes); // Reset edited notes when modal opens
    }
  }, [isOpen, notes]);

  const handleSave = () => {
    onSave(editedNotes);
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-800 flex">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
        <span className="absolute top-0 right-0 p-4">
          <button onClick={onClose}>X</button>
        </span>
        <h2 className="text-xl font-semibold">Edit Notes</h2>
        <textarea
          className="w-full p-2 my-4 border rounded"
          value={editedNotes}
          onChange={(e) => setEditedNotes(e.target.value)}
        ></textarea>
        <div className="flex justify-end gap-2">
          <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
            Cancel
          </button>
          <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotesModal;
