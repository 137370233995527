// import 'dotenv/config';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Amplify} from 'aws-amplify';
import '@aws-amplify/auth'
// import config  from './amplifyconfiguration.json';
import config from './aws-exports';
// import 'dotenv/config';
// Amplify.configure(config.Auth);
// interface AuthConfig {
//   Cognito: {
//     userPoolClientId: string;
//     userPoolId: string;
//     loginWith: {
//       oauth: {
//         domain: string;
//         scopes: string[];
//         redirectSignIn: string[];
//         redirectSignOut: string[];
//         responseType: "code" | "token";
//       };
//     };
//   };
// }

// if (!config.Auth.userPoolId || !config.Auth.userPoolWebClientId) {
//   console.error("Amplify Auth configuration is missing required properties.");
//   // Handle the error appropriately
// } else {
//   Amplify.configure({ Auth: config.Auth });
// }

const clonedConfig = JSON.parse(JSON.stringify(config));
// console.log("clonedConfig", clonedConfig)
Amplify.configure(clonedConfig);



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
