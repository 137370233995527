// BalanceSheetContext.tsx
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { fetch_data_by_reference_id, submitEditedData, submitNewEnteredData } from '../../api/axios_request';
import { useCustomer } from './useCustomer';
import { useNotification } from './useNotification';

export type TableData = {
  entry_id: string | undefined;
  id: string;
  purchase_date: string;
  style: string;
  size: string;
  size_variation_id?: string;
  price: string;
  refund: string;
  refund_date: string;
  item_name: string;
  quantity: string;
  notes: string;
  amount:string;
  client:string;
  client_id_purchased?:string;
  category:string;
  transaction_id?: string;
  cost?:string;
  extra_charge: string;
  gender?: string;
  reference_id?: string; // Ensure reference_id is part of the type definition


  // Add other fields as necessary
};

 type BalanceSheetContextType = {
    balanceData: TableData[];
    newRows: TableData[];
    editedRows: TableData[];
    addRow: (newRow: TableData) => void;
    submitNewAndEditedRows: () => Promise<void>;
    deleteNewRow: (entryId: string) => void;
    // updateExistingRow: (rowIndex: number, updatedRow: TableData) => void;
    updateNewRow: (entryId: any, updatedRow: TableData) => void; // Add this line,
    updateEditedRow: (entryId: string, updatedRow: TableData) => void; // Add this line,


};

// const BalanceSheetContext = createContext<BalanceSheetContextType>(null!);
const BalanceSheetContext = createContext<BalanceSheetContextType>({} as BalanceSheetContextType);

export const useBalanceSheet = () => useContext(BalanceSheetContext);
// Explicitly define the type for props
export const BalanceSheetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [balanceData, setBalanceData] = useState<TableData[]>([]);
    const [newRows, setNewRows] = useState<TableData[]>([]); // New, unsaved rows
    const [editedRows, setEditedRows] = useState<TableData[]>([]);
    const [originalData, setOriginalData] = useState<TableData[]>([]);

    const {customer} = useCustomer()
    const [error, setError] = useState(null);

    const { showNotification } = useNotification();


    useEffect(() => {
      if (customer?.reference_id) {
        setBalanceData([])
        setNewRows([])
        populateBalanceData(customer.reference_id)
              .catch(error => {
                  console.error("Failed to fetch balance data for new customer:", error);
              });
      }
    }, [customer?.reference_id]);



    const addRow = (newRow: TableData) => {
      setNewRows(prevNewRows => [newRow,...prevNewRows]);


    };

    const updateNewRow = (entryId: any, updatedRow: TableData) => {
      setNewRows(currentNewRows => {
        const rowIndex = currentNewRows.findIndex(row => row.entry_id === entryId);
        if (rowIndex === -1) {
          return currentNewRows;
        }
        // Create a new array with the updated row
        const updatedNewRows = [...currentNewRows];
        updatedNewRows[rowIndex] = updatedRow;
        return updatedNewRows;
      });
    };
  

    const submitNewAndEditedRows = async () => {
      // new rows
      if (newRows.length > 0) {
        const new_rows_and_customer = [customer, newRows]
        try {
          const response = await submitNewEnteredData(new_rows_and_customer); // Replace with your actual backend call
          // console.log(response)
          if (response.status) {
            // console.log("response", response, response.success)
            setBalanceData(currentData => [...newRows, ...currentData]);
            setNewRows([]);
            showNotification('Data successfully submitted!', 'success');

          }
        } catch (error) {
          console.error("Failed to submit new rows:", error);
          showNotification('Error submitting data.', 'error');

          // Handle errors as needed
        }
      }

      // edited rows
      if (editedRows.length > 0) {
        const rowsWithChanges = get_updated_rows_where_quantity_changed()
        // console.log("Submits the Edited Rows", editedRows)
        // console.log("Edited ROW, global", editedRows)
        const edited_rows_and_customer = [customer, editedRows, rowsWithChanges]
        // console.log("EDIT ROWS SUBMISSION: ", edited_rows_and_customer)
        try {
          const response = await submitEditedData(edited_rows_and_customer);
          if (response.status) {

            // Update balanceData with edited rows
            setBalanceData(currentData => {
              const updatedData = [...currentData];
              editedRows.forEach(editedRow => {
                const index = updatedData.findIndex(item => item.entry_id === editedRow.entry_id);
                if (index > -1) {
                  updatedData[index] = editedRow;
                }
              });
              return updatedData;
            });

            setEditedRows([]);
            setOriginalData(JSON.parse(JSON.stringify(balanceData))); // Deep copy to avoid reference issues
            showNotification('Data successfully submitted!', 'success');

          }
        } catch (error) {
          console.error("Failed to submit new rows:", error);
          showNotification('Error submitting data.', 'error');

          // Handle errors as needed
        }
      }

    };

    const get_updated_rows_where_quantity_changed = () => {
          // Transform editedRows to include necessary fields for rows with changed quantity
      const rowsWithChanges = editedRows.map(editedRow => {
        // Find the original row to compare quantities
        const originalRow = originalData.find(original => original.entry_id === editedRow.entry_id);
        // Since editedRows are pre-existing, originalRow should be defined. Include a safety check just in case.
        if (originalRow && editedRow.quantity !== originalRow.quantity) {
            return {
                entry_id: editedRow.entry_id,
                size_variation_id: editedRow.size_variation_id, 
                old_quantity: originalRow.quantity,
                new_quantity: editedRow.quantity,
                category: editedRow.category
            };
        }
        return null;
      }).filter(row => row !== null); // Filter out any nulls from rows that didn't have a quantity change
      return rowsWithChanges
    }
  

    const updateEditedRow = (entryId: string | undefined, updatedRow: TableData) => {
      if (!entryId) {
        console.error("Entry ID is undefined, cannot update row");
        return;
      }
      
      setEditedRows((prevEditedRows) => {
          const existingIndex = prevEditedRows.findIndex((row) => row.entry_id === entryId);
          if (existingIndex >= 0) {
              // Merge updates into existing row to preserve all edits
              const updatedEditedRows = [...prevEditedRows];
              updatedEditedRows[existingIndex] = { ...prevEditedRows[existingIndex], ...updatedRow };
              return updatedEditedRows;
          } else {
              // If for some reason the row isn't found, just add the new edit
              // This case shouldn't normally happen for existing rows
              return [...prevEditedRows, updatedRow];
          }
      });
  };
    

    const populateBalanceData = async (customer_reference_id: any) => {
      // setBalanceData([])
      try {
        const data = await fetch_data_by_reference_id(customer_reference_id);
        if (data && data.length > 0) {
          setBalanceData(data);
          setOriginalData(JSON.parse(JSON.stringify(data))); // Deep copy to avoid reference issues

        } else {
          console.error("No data received");
          setBalanceData([]);
      }
      } catch (error) {
        console.error("Error fetching existing data:", error);
        // throw new Error("Error fetching existing data:", error);
        return { success: false, message: "Failed to populate balance data.", error: error };
      }
    }


    const deleteNewRow = (entryId: string) => {
      // setNewRows(currentNewRows => currentNewRows.filter((_, index) => index !== rowIndex));
      setNewRows(currentNewRows => currentNewRows.filter(row => row.entry_id !== entryId));

    };
    

    return (
      <BalanceSheetContext.Provider value={{ balanceData, newRows,updateNewRow, addRow, submitNewAndEditedRows, editedRows,updateEditedRow, deleteNewRow }}>
      {children}
      </BalanceSheetContext.Provider>
    );
  };
  
