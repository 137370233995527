// hooks/useFetchItems.js
import { useState, useEffect } from 'react';
import { fetch_data_by_style_and_date_range } from '../../api/axios_request';
import { useLookUpItems } from './useLookUpItems';

const useFetchItems = (fetchParams: any, triggerFetch: any) => {
  // const [items, setItems] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  const {
    setItems, // fetch
    items,
    loading,
    setLoading,
    error,
    setError
  } = useLookUpItems();

  useEffect(() => {
    const fetchData = async () => {
      if (!triggerFetch) return; // Exit if not triggered
      
      setLoading(true);
      setError(null);
      try {
        // Simulate fetch call
        const response = await fetch_data_by_style_and_date_range(fetchParams);
        setItems(response);
      } catch (err) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchParams, triggerFetch]);

  return { items, loading, error };
};

export default useFetchItems;