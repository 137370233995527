import React, { useEffect, useState } from 'react';
import { signOut, fetchUserAttributes, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useUserContext } from './hooks/useUserContext';

interface HeaderProps {
  employeeName: string;
}

const Header: React.FC = () => {
  const { username, setUsername, userMasterAccessRights, setUserMasterAccessRights } = useUserContext();
  const { authStatus, user } = useAuthenticator();

  useEffect(() => {
      handleFetchUserAttributes()
  }, [authStatus]);


  async function handleFetchUserAttributes() {
    try {
      const userAttributes: any = await fetchUserAttributes();
      // const current_user= await getCurrentUser();
      const user_session: any = (await fetchAuthSession()).tokens ?? {};
      
      userAttributes.email ? setUsername(userAttributes.email) : setUsername("LogIn Again, Please");
      const userGroups = user_session.idToken.payload['cognito:groups'] || [];
      const isInMasterAccessGroup = userGroups.includes("master-access");
      isInMasterAccessGroup ?  setUserMasterAccessRights(true) : setUserMasterAccessRights(false)
    } catch (error) {
      console.log(error);
    }
  }


  const signOutUser = async () => {
    try {
      await signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  
  return (
    // <div className="flex justify-between items-center bg-gray-100 text-gray-800 py-2 px-4">
    <div className="flex justify-between items-center bg-grey shadow px-4 h-12 mb-2"> {/* Reduced height here */}
      <h1 className="text-lg font-semibold text-gray-800">Northdale MS</h1>
      <div className="flex items-center justify-center flex-1"> {/* Centered employee name */}
        <span className="text-sm text-gray-700">User email: {username}</span>
      </div>
      <button
        className="text-gray-800 bg-gray-100 hover:bg-gray-200 font-medium py-1 px-3 border border-gray-300 rounded transition duration-150 ease-in-out" 
        onClick={() => {signOutUser()}}
      >
        Logout
      </button>
    </div>

  );
};

export default Header;