// TempNotes.tsx
import React, { useEffect, useRef, useState } from 'react';
import { useNotes } from '../hooks/useNotesContext';


const TempNotes: React.FC = () => {
    const { notes, setNotes, handleSubmitNotes } = useNotes();
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  
    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      setNotes(value);
  
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
  
      debounceTimeout.current = setTimeout(() => {
        handleSubmitNotes(value); // Pass the latest notes value
      }, 2000);
    };

  return (
    <textarea
      value={notes}
      onChange={handleInputChange}
      className="w-full bg-transparent"
      placeholder="Add notes here..."
    />
  );
};

export default TempNotes;
