import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Customer } from '../types/customer_interface';

// Define an interface for your context state
interface CustomerContextState {
  customer: Customer | null;  // Replace 'any' with your customer type
  setCustomer: React.Dispatch<React.SetStateAction<any>>; // Same here
  otherMembers: Customer[] | null;
  setOtherMembers: React.Dispatch<React.SetStateAction<Customer[]>>;
}

// Provide a more accurate initial value
const CustomerContext = createContext<CustomerContextState>({
  customer: null,
  setCustomer: () => {}, // Now correctly typed,
  otherMembers: null,
  setOtherMembers: () => {},
});

export const useCustomer = () => useContext(CustomerContext);

interface CustomerProviderProps {
  children: ReactNode; // Correct type for children
}

export const CustomerProvider: React.FC<CustomerProviderProps> = ({ children }) => {
    const [customer, setCustomer] = useState<Customer | null>(null); // Use Customer type
    const [otherMembers, setOtherMembers] = useState<Customer[]>([]);


  return (
    <CustomerContext.Provider value={{ customer, setCustomer, otherMembers, setOtherMembers }}>
      {children}
    </CustomerContext.Provider>
  );
};
