// BalanceSheetTable.tsx
import React, { useCallback, useMemo, useState } from 'react';
import { useBalanceSheet, TableData } from '../hooks/useBalanceSheet';
import { BRAND_NAMES } from '../types/globals';
import { useInventory } from '../hooks/useInventory';
import { useCustomer } from '../hooks/useCustomer';
import { useRowUpdate, getTodayDate } from '../hooks/useRowUpdate';
import AddRowForm from './AddRowForm';
import { useUserContext } from '../hooks/useUserContext';
import NotesModal from '../NotesModal';
import TempNotes from './TempNotes';


const styles = {
    lineThrough: {
      textDecoration: 'line-through !important',
    },
    // You can add other styles here as needed
  };

const BalanceSheetTable = () => {
    const {inventory } = useInventory(); // Use fetchInventory from InventoryContext

    const { balanceData, newRows, submitNewAndEditedRows, editedRows } = useBalanceSheet();

    const {otherMembers, customer} = useCustomer();
    
    const { deleteNewRow } = useBalanceSheet();

    const { username, setUsername, userMasterAccessRights, setUserMasterAccessRights } = useUserContext();


    const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
    const [currentNotes, setCurrentNotes] = useState('');
    const [currentEntryId, setCurrentEntryId] = useState<string | undefined>(undefined);
    // const combinedData = [...newRows, ...balanceData];
    
    const combinedData = useMemo(() => {
        // Create a map of edited rows for quick access
        const editedRowMap = new Map(editedRows.map(row => [row.entry_id, row]));
        // console.log('Current state of balanceData:', balanceData);

        // Merge balanceData with edits from editedRows
        const mergedData = balanceData.map(row => editedRowMap.get(row.entry_id) || row);
      
            // Combine newRows and merged existing data, then sort by purchase_date
            const combinedAndSortedData = [...newRows, ...mergedData].sort((a, b) => {
                return b.purchase_date.localeCompare(a.purchase_date);
            });

        // Combine newRows and merged existing data
        // return [...newRows, ...mergedData];
        return combinedAndSortedData;
      }, [newRows, balanceData, editedRows]);
    // console.log("editedRows", editedRows)
    // console.log("newRows", newRows)
    const { handleInputChange } = useRowUpdate(); // Using the custom hook

    const openNotesModal = (entryId: string | undefined, notes: string) => {
        setCurrentEntryId(entryId);
        setCurrentNotes(notes);
        setIsNotesModalOpen(true);
      };
    
      const closeNotesModal = () => {
        setIsNotesModalOpen(false);
      };
    
      const saveNotes = (updatedNotes: string) => {
        // Here you can integrate the logic to update the notes in your state
        // For example, if using a state to hold your table's data:
        handleInputChange(currentEntryId, 'notes', updatedNotes); // Adjust this to match your existing logic for updating notes
      };

    const renderDateInput = (entryId:any) => {
        const isNewRow = newRows.some(r => r.entry_id === entryId);
        const isExistingRow = !isNewRow; // If it's not in newRows, it's considered existing (or handled as such)
        const row = combinedData.find(r => r.entry_id === entryId);

        if (!row || typeof row.purchase_date === 'undefined') {
          console.warn(`Row with entry_id ${entryId} is missing or does not have a date.`);
          return <input type="date" className="w-full" value={getTodayDate()} />;
        }
        return(
            <input
            type="date"
            // disabled={isExistingRow}
            value={row.purchase_date}
            onChange={(e) => handleInputChange(entryId, 'purchase_date', e.target.value)}
            className="w-full bg-transparent" 
        />
        );
    }

    const renderStyleInput = (entryId: any) => {
        const isNewRow = newRows.some(r => r.entry_id === entryId);
        const isExistingRow = !isNewRow; // If it's not in newRows, it's considered existing (or handled as such)

        const row = combinedData.find(r => r.entry_id === entryId);
          // If row is not found, return a placeholder or nothing at all
        if (!row) {
            return <div>Row not found</div>;
        }
        return (
            <>
                <select
                    value={row.style || ''}
                    // disabled={isExistingRow}
                    onChange={(e) => handleInputChange(entryId, 'style', e.target.value)}
                    className="w-full bg-transparent"
                >
                <option value="">Select Style</option> {/* Provide an option to select none or prompt */}
                    {BRAND_NAMES.map((brand, index) => (
                        <option key={index} value={brand}>{brand}</option>
                    ))}
                </select>
          </>
        );
    };


      // Render a dropdown or autocomplete for item_name
    const renderItemNameInput = (entryId: any, style: any) => {
        const row = combinedData.find(r => r.entry_id === entryId);
  
        // Determine if the row exists in newRows or balanceData/editedRows to check if it's an existing row
        const isNewRow = newRows.some(r => r.entry_id === entryId);
        const isExistingRow = !isNewRow; // If it's not in newRows, it's considered existing (or handled as such)
      
        // Based on the style, get the relevant items from the inventory
        // const items = isExistingRow ? [] : inventory[style] || [];
        const items = inventory[style] || [];

        // If row is not found, you might want to handle this scenario explicitly
        if (!row) {
          return <div>Row not found</div>; // Or any other fallback UI
        }
        // end new

        return (
            <>
            <input
              list={`item-names-${entryId}`}
              className="w-full bg-transparent"
            //   value={row.item_name || ''}
            // disabled={isExistingRow}
            value={row.item_name || ''}
            //   value={combinedData[rowIndex].item_name || ''}
            //   onChange={(e) => handleItemNameInputChange(rowIndex, style, e.target.value)}
            onChange={(e) => handleInputChange(entryId, 'item_name', e.target.value)}

              // Value and onChange handler for item_name
            />
            <datalist id={`item-names-${entryId}`}>
              {items.map((item, index) => (
                <option key={index} value={item.item_name} />
              ))}
            </datalist>
          </>
        );
    };

    const renderSizeInput = (entryId: any) => {
            // Find the row by entry_id
            const row = combinedData.find(r => r.entry_id === entryId);
            if (!row) {
                return <div>Row not found</div>;
            }
            // Determine if the row exists in newRows to check if it's a new row
            const isNewRow = newRows.some(r => r.entry_id === entryId);
            // const isExistingRow = !isNewRow; // If it's not in newRows, it's considered existing (or handled as such)

            // Assuming `inventory` structure is { [style: string]: { item_name: string, sizes: Array<{size_name: string, item_variation_id: any}> }[] }
            // const selectedItem = isNewRow && row ? inventory[row.style]?.find(item => item.item_name === row.item_name) : undefined;
            const selectedItem = inventory[row.style]?.find(item => item.item_name === row.item_name);

            // For new rows, get sizes from selectedItem; for existing rows, use the row's current size
            // const sizes = isNewRow && selectedItem ? selectedItem.sizes || [] : row && row.size ? [{ size_name: row.size, item_variation_id: row.size_variation_id }] : [];
                // Initialize sizes from selectedItem; if selectedItem is undefined, default to an empty array
            let sizes = selectedItem?.sizes || [];

                // Ensure the current size is included in the list for existing rows
                if (!isNewRow && row.size && !sizes.some(size => size.size_name === row.size)) {
                    // Assuming 'default_item_id_size' is the value you can use or derive for 'item_id_size'
                    // Adjust this based on how you can actually get or set 'item_id_size'
                    const default_item_id_size = "your_logic_here_to_determine_item_id_size"; 
                    // Provide a default value for 'item_variation_id' if 'row.size_variation_id' is undefined
                    const item_variation_id = row.size_variation_id || ""; // Fallback to an empty string or another appropriate default value
                    sizes = [...sizes, { 
                        size_name: row.size, 
                        item_variation_id: item_variation_id, // This now guarantees 'item_variation_id' is a string
                        item_id_size: default_item_id_size
                    }];
                }

            // Use the selected size and variation if available
            // const selectedOptionValue = JSON.stringify(sizes.find(size => size.size_name === row.size && size.item_variation_id === row.size_variation_id) || '');

            const selectedOptionValue = row && row.size && row.size_variation_id
                ? JSON.stringify({ size_name: row.size, item_variation_id: row.size_variation_id })
                : '';

            if (!row) {
                return <div>Row not found</div>; // Or any other fallback UI
            }
        // end new

        return (
            <select
            className="w-full bg-transparent"
            value={selectedOptionValue}
            // disabled={isExistingRow}
            onChange={(e) => {
                const selectedOption = JSON.parse(e.target.value);
                handleInputChange(entryId, 'size', selectedOption.size_name, selectedOption.item_variation_id);
            }}
        >
            {sizes.map((size, index) => (
                <option key={index} value={JSON.stringify({ size_name: size.size_name, item_variation_id: size.item_variation_id })}>
                    {size.size_name}
                </option>
            ))}
            {/* {!isExistingRow ? (
                // Map over sizes for new rows where sizes are available
                sizes.map((size, index) => (
                    <option key={index} value={JSON.stringify({ size_name: size.size_name, item_variation_id: size.item_variation_id })}>
                        {size.size_name}
                    </option>
                ))
            ) : (
                // For existing rows, display the current size as the only option
                <option value={selectedOptionValue}>{row.size}</option>
            )} */}
        </select>
        );
    };

    const renderPriceInput = (entryId: any) => {
        // const row = combinedData[rowIndex];


        // new
        const row = combinedData.find(r => r.entry_id === entryId);

        if (!row) {
          return <div>Row not found</div>; // Or any other appropriate fallback UI
        }
        // end new
        return (
            <input
                type="text"
                className="w-full bg-transparent"
                value={row.price || ''}
                // onChange={handlePriceChange}
                onChange={(e) => handleInputChange(entryId, 'price', e.target.value)}

            />
        );
    };

    const renderCostInput = (entryId: any) => {
        // const row = combinedData[rowIndex];


        // new
        const row = combinedData.find(r => r.entry_id === entryId);

        if (!row) {
          return <div>Row not found</div>; // Or any other appropriate fallback UI
        }
        return (
            <input
                type="text"
                className="w-full bg-transparent"
                value={row.cost || ''}
                // onChange={handlePriceChange}
                onChange={(e) => handleInputChange(entryId, 'cost', e.target.value)}
            />
        );
    };


    const renderQuantityInput = (entryId: any) => {
        // const row = combinedData[rowIndex];

        // new
        const row = combinedData.find(r => r.entry_id === entryId);

        if (!row) {
            return <div>Row not found</div>; // Or any other appropriate fallback UI
        }
        // end new
        return (
            <input
                type="text"
                className="w-full bg-transparent"
                value={row.quantity || ''}
                // onChange={handlePriceChange}
                onChange={(e) => handleInputChange(entryId, 'quantity', e.target.value)}

            />
        );
    };

    const renderRefundInput = (entryId: any) => {
        const row = combinedData.find(r => r.entry_id === entryId);
        if (!row) {
            return <div>Row not found</div>; // Or any other appropriate fallback UI
        }
        return (
            <input
                type="text"
                className="w-full bg-transparent"
                value={row.refund || ''}
                // onChange={handleRefundChange}
                onChange={(e) => handleInputChange(entryId, 'refund', e.target.value)}
            />
        );
    };

    const renderRefundDate = (entryId:any) => {
        const isNewRow = newRows.some(r => r.entry_id === entryId);
        const isExistingRow = !isNewRow; // If it's not in newRows, it's considered existing (or handled as such)
        const row = combinedData.find(r => r.entry_id === entryId);
        return(
            <input
            type="date"
            value={row?.refund_date}
            onChange={(e) => handleInputChange(entryId, 'refund_date', e.target.value)}
            className="w-full bg-transparent" 
        />
        );
    }
    
    
    const renderExtraChargeInput = (entryId: any) => {
        const row = combinedData.find(r => r.entry_id === entryId);

        if (!row) {
          return <div>Row not found</div>; // Or any other appropriate fallback UI
        }
        // end new
        return (
            <input
                type="text"
                className="w-full bg-transparent"
                value={row.extra_charge || ''}
                // onChange={handlePriceChange}
                onChange={(e) => handleInputChange(entryId, 'extra_charge', e.target.value)}

            />
        );
    };

    const renderNotesInput = (entryId: any) => {
        // const row = combinedData[rowIndex];

        // new
        const row = combinedData.find(r => r.entry_id === entryId);

        if (!row) {
            return <div>Row not found</div>; // Or any other appropriate fallback UI
        }
        // end new
        const displayNotes = row?.notes ? row.notes : 'Add Notes'; // Show 'Add Notes' if no notes are present

        return (
            <div 
            onClick={() => openNotesModal(entryId, row?.notes || '')} 
            className="cursor-pointer relative" 
            style={{ maxWidth: '250px', overflowX: 'auto', whiteSpace: 'nowrap' }}
          >
            {/* Display notes with horizontal scrolling if they exceed the max width */}
            <span 
              className="text-sm text-gray-600 hover:text-gray-800" 
              title="Click to view/edit notes"
            >
              {displayNotes}
            </span>
          </div>
        );
    };
    
    const renderClientInput = (entryId: any) => {
        // const row = newRows[rowIndex];
        // const row = combinedData?.[rowIndex];

        // new
        const row = combinedData.find(r => r.entry_id === entryId);

        if (!row) {
            return <div>Row not found</div>; // Or any other appropriate fallback UI
        }
        // end new
        const selectedClientValue = otherMembers?.find(member => 
            `${member.given_name} ${member.family_name}` === row.client
          ) ? JSON.stringify({
            client: `${row.client}`,
            client_id_purchased: otherMembers.find(member => 
              `${member.given_name} ${member.family_name}` === row.client
            )?.id
          }) : '';

        return (
            <select
            className="w-full bg-transparent"
            value={selectedClientValue}

            // value={row.client || ''}
            onChange={(e) => {
                const selectedOption = JSON.parse(e.target.value);
                handleInputChange(entryId, 'client', selectedOption.client, selectedOption.client_id_purchased);

                // handleInputChange(entryId, 'client', selectedOption.client, selectedOption.client_id_purchased);
            }}
            // onChange={(e) => handleInputChange(rowIndex, 'client', e.target.value)}
        >
            {/* <option value={customer?.family_name + "" + customer?.given_name}></option> Default empty option */}
            {otherMembers && otherMembers.map((member, index) => (
                // <option key={index} value={member.given_name}>
                <option key={index} value={JSON.stringify({ client: member.given_name+' '+member.family_name, client_id_purchased: member.id })}>

                    {member.given_name + " " + member.family_name}
                </option>
            ))}
        </select>
        );
    };

    const renderDeleteButton = (entryId: any) => {

        // const isNewRow = rowIndex < newRows.length;
      
        // new
        const isNewRow = newRows.some(row => row.entry_id === entryId);
        const handleDelete = () => {
            if (isNewRow) {
              deleteNewRow(entryId);
            } else {
              // Handle deletion of existing rows differently if necessary
              // This could involve setting a flag to mark the row for deletion,
              // directly removing it from the state, or making a request to delete it from the backend.
            }
          };
        // end new

        // If it's a new row, return a delete button
        if (isNewRow) {
          return (
            <button
            //   onClick={() => deleteNewRow(entryId)}
                onClick={handleDelete}
              className="text-red-500 hover:text-red-700 p-2"
              aria-label="Delete row"
            >
              Delete
            </button>
          );
        }
      
        // For existing rows, return null or an empty fragment
        return null;
      };
      

        // const calculateTotalBalance = () => {
        const totalBalance = useMemo(() => {
            const sumTotalAmountMedical = combinedData.reduce((acc, row) => {
                if ( (Array.isArray(row.category) && row.category.includes('MEDICAL')) || row.category === 'MEDICAL') {
                // if (row.category === 'MEDICAL') {
                    return acc + Number(row.amount || 0);
                }
                return acc;
            }, 0);
    
            const sumRefund = combinedData.reduce((acc, row) => (
                acc + Number(row.refund || 0)
            ), 0);

            const sumExtraCharge = combinedData.reduce((acc, row) => (
                acc + Number(row.extra_charge || 0)
            ), 0);
    
            const sumTotalAmountOther = combinedData.reduce((acc, row) => {
                // if (row.category !== 'MEDICAL') {
                if (!(Array.isArray(row.category) ? row.category.includes('MEDICAL') : row.category === 'MEDICAL')) {
                    return acc + Number(row.amount || 0);
                }
                return acc;
            }, 0);
            return (sumTotalAmountMedical - sumRefund - sumTotalAmountOther - sumExtraCharge).toFixed(2);
        // };
    }, [combinedData]);
    // ${row.category === 'MEDICAL' ? 'bg-gray-200' : 'bg-white'}

    const NetSales = useMemo(() => {

        const sumTotalAmount = combinedData.reduce((acc, row) => {
            return acc + Number(row.amount || 0);
        }, 0);

        const sumTotalCost = combinedData.reduce((acc, row) => (
            acc + (Number(row.cost || 0) * Number(row.quantity || 1))
        ), 0);

        const sumRefund = combinedData.reduce((acc, row) => (
            acc + Number(row.refund || 0)
        ), 0);

        return (sumTotalAmount - sumTotalCost - sumRefund).toFixed(2);
    // };
    }, [combinedData]);
    
  return (
    <div className="overflow-x-auto">
        <div className="mt-2 mb-2 p-2 bg-gray-50 rounded-lg shadow flex justify-between items-center mb-4">
            {/* <div className="text-lg font-semibold">Purchases</div> */}
        <div className="flex flex-col"> {/* Changed to flex-col to stack elements vertically */}
            <div className="flex mt-4"> {/* "Total Balance" span */}
                <span className="text-md font-medium text-gray-700">Total Balance: </span>
                <span className="text-md font-medium text-blue-500">{totalBalance}</span>
            </div>

            <div className="flex mt-2"> {/* "Total Balance" span */}
                <span className="text-md font-medium text-gray-700">Notes:&nbsp;&nbsp;</span>
                <TempNotes />
            </div>

            {userMasterAccessRights && (
                <div className="flex mt-2"> {/* "Net Sales" span */}
                    <span className="text-md font-medium text-gray-700">Net Sales: </span>
                    <span className="text-md font-medium text-blue-500">{NetSales}</span>
                </div>
            )}

        </div>
            <AddRowForm />
            <button
  className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 transition duration-300 ease-in-out"
  onClick={submitNewAndEditedRows}
  >
    Submit Items
  </button>
</div>



<div className="overflow-y-auto max-h-[65vh]">

    <table className="min-w-full leading-normal " >
        <thead>
            <tr>
                <th className="px-3 py-3 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-12">ID</th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider whitespace-nowrap">Purchase Date</th>
                {['Style','Item Name', 'Size', 'Total Amount', 'Quantity', 'Price Per Item', 'Refund', 'Refund Date', 'Extra Charge', 'Client', 'Notes' , 'Category', 'Gender'].map((header, index, arr) => (
                    <th
                        key={header}
                        className={`px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider whitespace-nowrap ${

                        // className={`min-w-90 px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider ${
                            index !== arr.length - 1 ? 'border-r' : ''
                        }`}
                    >
                        {header}
                    </th>
                ))}
                {userMasterAccessRights && (
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider whitespace-nowrap">Cost</th>
                )}
                <th className="w-32 px-5 py-3 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Delete</th>

            </tr>
        </thead>

        <tbody>
                    {combinedData.map((row: any, index: any) => (
                        <tr key={row.entry_id} 
                        className={`border-b border-gray-300 transition-colors duration-300 
                                    ${row.style.startsWith('TEST') ? 'bg-[#3b82f6] text-white' : 
                                            (Array.isArray(row.category) ? row.category.includes('MEDICAL') : row.category === 'MEDICAL') ? 'bg-gray-200' : 'bg-transparent'}
                                            ${Number(row.quantity) === 0 ? 'opacity-35' : ''}
                                    `}
                            >
                                {/* row.category === 'MEDICAL' ? 'bg-gray-200' : 'bg-transparent'} */}
                                {/* ${row.style.startsWith('TEST') ? 'bg-[#d1d5db] text-white' : ''} */}
                            {/* <td className="px-3 py-3 border-b border-r border-gray-200 bg-white text-sm text-center"> */}
                            <td className={`px-3 py-3 border-b border-r border-gray-300 text-md text-center`}>
                                {/* {combinedData.length - rowIndex} */}
                                {combinedData.length - index} {/* Adjusted row numbering */}
                            </td>
                            <td className={`w-32 px-5 py-3 border-b border-r border-gray-300 text-md`}>

                                {renderDateInput(row.entry_id)}
                            </td>
                            
                            {/* <td className="w-92 px-5 py-3 border-b border-r border-gray-200 bg-white text-sm"> */}
                            <td className={`px-5 py-3 border-b border-r border-gray-300 text-left text-md uppercase tracking-wider w-1/4 min-w-[200px] truncate`}>

                                {renderStyleInput(row.entry_id)}
                            </td>
                            {/* <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm"> */}
                            <td className={`px-5 py-3 border-b border-r border-gray-300 text-left text-md uppercase tracking-wider w-1/4 min-w-[500px] truncate`}>

                                {renderItemNameInput(row.entry_id, row.style)}
                            </td>
                            <td className={`px-5 py-3 border-b border-r border-gray-300 text-left text-md uppercase tracking-wider w-1/4 min-w-[200px] truncate`}>
                                {renderSizeInput(row.entry_id)}

                                {/* {row.size} */}
                            </td>
                            <td className={`px-5 py-3 border-b border-r border-gray-300 text-md `}>
                                {/* {renderAmountInput(rowIndex)} */}
                                {row.amount}

                                {/* {row.price} */}
                            </td>
                            <td className={`px-5 py-3 border-b border-r border-gray-300 text-md `}>
                                {renderQuantityInput(row.entry_id)}
                                {/* {row.price} */}
                            </td>

                            <td className="px-5 py-3 border-b border-r border-gray-300 text-md">
                                {renderPriceInput(row.entry_id)}
                                {/* {row.price} */}
                            </td>
                            <td className="px-5 py-3 border-b border-r border-gray-300 text-md">
                                {renderRefundInput(row.entry_id)}
                                {/* {row.refund} */}
                            </td>
                            <td className="px-5 py-3 border-b border-r border-gray-300 text-sm">
                                {/* {row.refund_date} */}
                                {renderRefundDate(row.entry_id)}
                            </td>
                            <td className="px-5 py-3 border-b border-r border-gray-300 text-sm">
                                    {renderExtraChargeInput(row.entry_id)}
                            </td>
                            <td className="px-5 py-3 border-b border-r border-gray-300 text-left text-xs uppercase tracking-wider w-1/4 min-w-[250px] truncate">
                                {renderClientInput(row.entry_id)}
                                {/* {row.notes} */}
                            </td>
                            <td className="px-5 py-3 border-b border-r border-gray-300 text-left text-xs uppercase tracking-wider w-1/4 min-w-[250px] truncate">
                                {renderNotesInput(row.entry_id)}
                                {/* {row.notes} */}
                            </td>
                            <td className="px-5 py-3 border-b border-r border-gray-300 text-sm">
                                {row.category}
                            </td>
                            <td className="px-5 py-3 border-b border-r border-gray-300 text-sm">
                                {/* {renderDeleteButton(row.gender)} */}
                                {Array.isArray(row.gender) ? row.gender.join(', ') : row.gender || ''}
                            </td>
                            {userMasterAccessRights && (
                                <td className="px-5 py-3 border-b border-r border-gray-300 text-sm">
                                    {renderCostInput(row.entry_id)}

                                </td>
                            )}
                            <td className="px-5 py-3 border-b border-r border-gray-300 text-sm">
                                {renderDeleteButton(row.entry_id)}
                            </td>
                        </tr>
                    ))}
                </tbody>
    </table>
    </div>
    {combinedData.length === 0 && <div>No data available</div>}
          <NotesModal
        isOpen={isNotesModalOpen}
        notes={currentNotes}
        onClose={closeNotesModal}
        onSave={saveNotes}
      />
</div>
  );
};

export default BalanceSheetTable;

