import React from 'react';
import { useSearch } from './hooks/SearchContext';

const SearchBar: React.FC = () => {
  // const { searchTerm, setSearchTerm, handleSearch } = useSearch();
  const { searchTerm, setSearchTerm, handleSearch } = useSearch<any>(); // Use 'any' or a specific type
  
  // Function to handle key down events
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(searchTerm);
    }
  };


  return (
    <div className="flex border-2 rounded">
      <input
        type="text"
        placeholder="Search"
        className="px-4 py-2 w-full"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress

      />
      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleSearch(searchTerm)}>Search</button>
    </div>
  );
};

export default SearchBar;
