// SearchContext.tsx

// SearchContext.tsx

import React, { createContext, useContext } from 'react';

// Define the context type
interface SearchContextType<T> {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: (term: string) => void;
  searched_items: T[];
  setItems: React.Dispatch<React.SetStateAction<T[]>>;
  selectedItem: T | null;
  setSelectedItem: React.Dispatch<React.SetStateAction<T | null>>;
}

// Create the context with a generic placeholder
const SearchContext = createContext<SearchContextType<any> | undefined>(undefined);

// Provide a typed version of useContext for this specific context
export function useSearch<T>() {
  const context = useContext(SearchContext as React.Context<SearchContextType<T> | undefined>);
  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
}

// Export the SearchContext itself for use in the provider
export { SearchContext };
