// History.tsx
import React, { useMemo, useState } from 'react';
import DynamicTable from './DynamicTable';
import { useBalanceSheet } from './hooks/useBalanceSheet';
import useFilter from './hooks/useFilter';
import { useCustomer } from './hooks/useCustomer';
import { BRAND_NAMES } from './types/globals';
import Select, { MultiValue, OptionsOrGroups, GroupBase } from 'react-select';
import { useLookUpItems } from './hooks/useLookUpItems';
// Define your own option type
interface OptionType {
  label: string;
  value: string;
}
const PurchaseHistory = () => {

  const { balanceData } = useBalanceSheet();
  // Define the initial filter configuration
  const { customer, otherMembers } = useCustomer(); // Access the customer from the global state
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  // const { selectedStyles, setSelectedStyles, setItems } = useLookUpItems();
  const [selectedStyles, setSelectedStyles] = useState<{ value: string; label: string; }[]>([]);

  // const { selectedStyles, setSelectedStyles, setItems } = useLookUpItems();

  const styles = BRAND_NAMES
  const styleOptions = styles.map(style => ({ value: style, label: style }));

//   customers, styles, item
  const initialFilters = {
    customer_id: '',
    // style: '',
    style: [],
    item_name: '',
  };

    // Use the custom filter hook
    // Handlers for customer selection
    const handleCustomerChange = (e: any) => {
        setSelectedCustomerId(e.target.value);
      };
    // Handlers for filter changes that call `setFilter` from the custom hook
//   const handleCustomerChange = (e: any) => setFilter('client', e.target.value);
  // const handleStyleChange = (e: any) => setFilter('style', e.target.value);
  // const handleStyleChange = (selectedOptions: MultiValue<OptionType>) => {
  //   const selectedStyles = selectedOptions.map(option => option.value);
  //   setFilter('style', selectedStyles);
  // };
  const handleStyleChange = (selectedOptions: MultiValue<OptionType> | null) => {
    // Create a new array from selectedOptions, ensuring it is mutable and matches the expected type
    const newSelectedStyles = selectedOptions ? Array.from(selectedOptions) : [];
  
    setSelectedStyles(newSelectedStyles);
  
    // If necessary, update the filter with just the values
    const selectedStylesForFilter = newSelectedStyles.map(option => option.value);
    setFilter('style', selectedStylesForFilter);
  };
  const handleSearchChange = (e: any) => setFilter('item_name', e.target.value);


  // Headers for the DynamicTable
  const headers = ['Purchase Date', 'Style', 'Item Name', 'Size', 'Total Amount', 'Quantity', 'Price Per Item', 'Refund', 'Refund Date', 'Extra Charge', 'Notes', 'Client', 'Category', 'Gender'];

//   Filter balanceData for 'MEDICAL' category items and map to the expected format for DynamicTable
const medicalItemsData = useMemo(() => {
  return balanceData
    .filter(item => 
        // item.category === 'MEDICAL' && 
        (Array.isArray(item.category) ? item.category.includes('MEDICAL') : item.category === 'MEDICAL') &&
        (selectedCustomerId === '' || item.client_id_purchased === selectedCustomerId)
    )
    .sort((a, b) => new Date(b.purchase_date).getTime() - new Date(a.purchase_date).getTime()) // Modified line for descending order
    .map(item => {
        const customerName = otherMembers?.find(customer => customer.id === item.client_id_purchased)?.given_name + ' ' + otherMembers?.find(customer => customer.id === item.client_id_purchased)?.family_name || 'Unknown Customer';
      return {
          purchase_date: item.purchase_date,
          style: item.style,
          item_name: item.item_name,
          size: item.size,
          total_amount: item.amount,
          quantity: item.quantity,
          price_per_item: item.price,
          refund: item.refund,
          refund_date: item.refund_date,
          extra_charge: item.extra_charge,
          notes: item.notes,
          client: customerName, // Assuming you want to display the customer name
          category: item.category,
          gender: Array.isArray(item.gender) ? item.gender.join(', ') : item.gender || ''
      }
    });

}, [balanceData, otherMembers, selectedCustomerId]); // Ensure selectedCustomerId is a dependency if used in filter

  
  const { filteredData, setFilter,clearFilters } = useFilter(medicalItemsData, initialFilters);


  const handleClearFilters = () => {
    clearFilters(); // Reset filters
    setSelectedStyles([]); // Additionally, reset selected styles in the global context
    // Reset any other UI/state elements as needed
    // setSelectedCustomerId("Select Customer"); 
  };
  
  return (
    <div>
        <div className="filters flex justify-between mb-4">
        {/* Customer filter dropdown */}

        <select onChange={handleCustomerChange} className="p-2 rounded"> 
            <option value="">Select Customer</option>
            {otherMembers?.map((customer) => (
                <option key={customer.id} value={customer.id}>{customer.given_name + ' '+ customer.family_name}</option>
            )) ?? <option disabled>No customers available</option>}
        </select>

        {/* Style filter dropdown */}
        <Select
        isMulti
        value={selectedStyles} // This should reflect the state from `useLookUpItems`
        options={styleOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleStyleChange}
      />

        {/* Item name search bar */}
        <input
          type="text"
          onChange={handleSearchChange}
          placeholder="Search Item Name"
          className="p-2 rounded"
        />

        {/* Clear filters button */}
        <button onClick={handleClearFilters} className="p-2 bg-red-500 text-white rounded">
          Clear Filters
        </button>
      </div>
        <DynamicTable headers={headers} data={filteredData} />
    </div>
  );
};

export default PurchaseHistory;