import React, {createContext, ReactNode, useContext, useState} from 'react';

// Create the context
interface IUserContext {
    username: string;
    setUsername: React.Dispatch<React.SetStateAction<string>>;
    userMasterAccessRights: boolean;
    setUserMasterAccessRights: React.Dispatch<React.SetStateAction<boolean>>;


}

type UserProviderProps = {
    children: ReactNode;
};

// Set the default value for the context
const UserContext = createContext<IUserContext>({
    username: '',
    setUsername: () => {},
    userMasterAccessRights: false,
    setUserMasterAccessRights: () => {}
});

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [username, setUsername] = useState<string>('');
    const [userMasterAccessRights, setUserMasterAccessRights] = useState<boolean>(false);

    return (
        <UserContext.Provider value={{ username, setUsername, userMasterAccessRights, setUserMasterAccessRights }}>
            {children}
        </UserContext.Provider>
    );
};
