// AddRowForm.tsx
import React, { useState } from 'react';
import { useBalanceSheet, TableData } from '../hooks/useBalanceSheet';
import { v4 as uuidv4 } from 'uuid';
import { getTodayDate } from '../hooks/useRowUpdate';

const AddRowForm = () => {
  const { addRow } = useBalanceSheet();
  const [newRow, setNewRow] = useState<TableData>({ entry_id: '' ,id: '', purchase_date: '', style: '', item_name: '', size: '', price: '', refund: '', refund_date: '', notes: '', quantity: '', amount: '', extra_charge: '' , client: '',client_id_purchased: '', category: '', transaction_id: '', cost:'', gender: ''});

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const rowWithUniqueId = { ...newRow, entry_id: uuidv4(), purchase_date: getTodayDate()};
    addRow(rowWithUniqueId);

    // addRow(newRow);
    setNewRow({ entry_id: '', id: '', purchase_date: '', style: '',item_name: '', size: '', price: '', refund: '', refund_date: '', notes: '', quantity: '', amount: '', extra_charge: '' , client: '', client_id_purchased: '', category: '', transaction_id:'',  cost:'', gender: '' });
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Form fields */}
      {/* <button type="submit">Add Row</button> */}
      <button
        type="submit"
        className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 transition duration-300 ease-in-out"
        >
          Add Row
      </button>
      
    </form>
  );


  
  // Form fields and submit button
};

export default AddRowForm;
