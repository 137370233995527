// components/LookUpItems.js
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select, { GroupBase, MultiValue, OptionsOrGroups } from 'react-select';
import DynamicTable from '../DynamicTable';
import Dropdown from '../Dropdown';
import { BRAND_NAMES } from '../types/globals';
import useFetchItems from '../hooks/useFetchItems';
import { getTodayDate } from '../hooks/useRowUpdate';
import { useLookUpItems } from '../hooks/useLookUpItems';
import { useUserContext } from '../hooks/useUserContext';
import { fetch_missing_inventory_options } from '../../api/axios_request';

interface DropdownProps {
    options: string[];
    onSelect: (value: string) => void;
    placeholder?: string;
}

interface FetchMissingInventoryResponse {
    items: Record<string, string>; // Assuming it's an object with keys and values as strings
    next_cursor: string | null;   // Cursor for pagination
}
const LookUpInventory = () => {
    const { userMasterAccessRights } = useUserContext();

    const options = [
        { label: "Check Missing Category", value: "category" },
        { label: "Check Missing Gender", value: "gender" },
        { label: "Check Missing Price", value: "price" },
        // { label: "Check Missing Cost", value: "cost" },
    ];
  
    // Include 'Cost' property only if user is admin
    if (userMasterAccessRights) {
        options.push({ label: "Check Missing Cost", value: "cost" })
    }
    const defaultOptions = ["Check the Price", "Check the Gender", "Check the Category"];
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleSelectOption = useCallback(async (option: string) => {
        setSelectedOption(option);
        setIsLoading(true);
        setError(null);
    
        let allData: any[] = [];
        let nextCursor: string | null = null;
    
        try {
            do {
                // Fetch data with optional cursor
                const response: FetchMissingInventoryResponse = await fetch_missing_inventory_options(option, nextCursor);
    
                // Process the response data
                const formattedData = Object.entries(response.items).map(([key, value]) => ({
                    id: key,
                    item: value
                }));
                allData = [...allData, ...formattedData]; // Append to the accumulated data
    
                // Update the cursor for the next fetch
                nextCursor = response.next_cursor;
    
            } while (nextCursor); // Continue fetching if there’s more data
    
            setData(allData); // Update the state with all fetched data
        } catch (err) {
            setError("Failed to fetch data. Please try again.");
            console.error("Error fetching data:", err);
        } finally {
            setIsLoading(false);
        }
    }, []);
    // const handleSelectOption = useCallback(async (option: string) => {
    //     setSelectedOption(option);
    //     setIsLoading(true);
    //     setError(null);

    //     try {
    //         const response = await fetch_missing_inventory_options(option); // Call the API directly

    //         const formattedData = Object.entries(response).map(([key, value]) => ({
    //             id: key,
    //             item: value
    //         }));
    //         setData(formattedData); // Update the state with fetched data
    //     } catch (err) {
    //         setError("Failed to fetch data. Please try again.");
    //         console.error("Error fetching data:", err);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // }, []);


    return (

        <div>   
            <h2 className="text-xl font-semibold mb-4">Check Missing Information in The Inventory</h2>
            {/* <Dropdown options={defaultOptions} placeholder="Select an option" onSelect={handleSelectOption} /> */}
            <Dropdown
                options={options.map(opt => opt.label)}
                placeholder="Select an option"
                onSelect={(value) => {
                    const optionValue = options.find(opt => opt.label === value)?.value;
                    if (optionValue) handleSelectOption(optionValue);
                }}
            />

            {selectedOption && (
                <div className="mt-4">
                    {isLoading ? (
                        <p className="text-gray-500">Loading...</p>
                    ) : error ? (
                        <p className="text-red-500">{error}</p>
                    ) : (
                        <DynamicTable headers={["Item ID", "Item Name"]} data={data} />
                    )}
                </div>
            )}
        </div>
    );
};

export default LookUpInventory;

