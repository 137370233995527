import { useCallback } from "react";
import { useBalanceSheet } from "./useBalanceSheet";
import { useInventory } from "./useInventory";
import { useCustomer } from "./useCustomer";

export const useRowUpdate = () => {
  const { updateNewRow, newRows, balanceData, updateEditedRow, editedRows } = useBalanceSheet();
  const { fetchInventory, inventory } = useInventory(); // Use fetchInventory from InventoryContext
  const {customer} = useCustomer()

  const handleInputChange = useCallback((entryId: any, field: string, value: any, other_value?: any) => {
    let isNewRow = newRows.some(row => row.entry_id === entryId);
    let row;
    let rowIndex;

    if (isNewRow) {
      rowIndex = newRows.findIndex(row => row.entry_id === entryId);
      row = newRows[rowIndex];
      const updatedRow = { ...row, [field]: value };
      // Custom logic for adjusting row fields as necessary
      const adjustedRow = adjustRowFields(updatedRow, field, value, other_value, fetchInventory, inventory, customer);
      updateNewRow(entryId, adjustedRow);
    } else {
      rowIndex = editedRows.findIndex(row => row.entry_id === entryId);
      if (rowIndex !== -1) {
        // Row is already in editedRows
        row = editedRows[rowIndex];
      } else {
        // It's an existing row that hasn't been edited before
        rowIndex = balanceData.findIndex(row => row.entry_id === entryId);
        row = balanceData[rowIndex];
      }

      const updatedRow = { ...row, [field]: value };
      // Custom logic for adjusting row fields as necessary
      const adjustedRow = adjustRowFields(updatedRow, field, value, other_value, fetchInventory, inventory, customer);
      updateEditedRow(entryId, adjustedRow);
    }
}, [updateNewRow, updateEditedRow, newRows, balanceData, editedRows, customer, inventory]);

  return { handleInputChange };
};


export function getTodayDate() {
  const now = new Date();
  const localTime = now.getTime();
  const localOffset = now.getTimezoneOffset() * 60000; // Convert offset to milliseconds
  const localNow = new Date(localTime - localOffset);
  return localNow.toISOString().split('T')[0];
}



const adjustRowFields = (row: any, field: any, value: any, other_value: any, fetchInventory: any, inventory?: any, customer?: any) => {
  let updatedRow = { ...row };

  switch (field) {
    case 'style':
      updatedRow.item_name = '';
      updatedRow.size = '';
      updatedRow.size_variation_id = '';
      updatedRow.style = value
      if (!inventory[value]) {
        fetchInventory(value)
      }
      break;
    case 'quantity':
    case 'price':
      const quantity = Number(updatedRow.quantity) || 0;
      const price = Number(updatedRow.price) || 0;
      updatedRow.amount = (quantity * price).toFixed(2);
      break;
    case 'refund':
      value = Number(value) || "0";
      updatedRow.refund = value;
      updatedRow.refund_date = (value || "0") ? getTodayDate() : '';
      break;
    case 'size':
      updatedRow.size = value;
      updatedRow.size_variation_id = other_value ? other_value : '';
      break;
     case 'item_name':
      const itemsForStyle = inventory[row.style] || [];
      const selectedItem = itemsForStyle.find((item: any) => item.item_name === value);
      if (selectedItem) {
        updatedRow.item_name = value;
        updatedRow.id = selectedItem.id;
        updatedRow.size = selectedItem.sizes.length > 0 ? selectedItem.sizes[0].size_name : '';
        updatedRow.size_variation_id = selectedItem.sizes.length > 0 ? selectedItem.sizes[0].item_variation_id : '';
        updatedRow.price = (Number(selectedItem.price / 100)).toFixed(2).toString();
        updatedRow.quantity = '1';
        updatedRow.category = selectedItem.category;
        updatedRow.amount = (Number(selectedItem.price / 100)).toFixed(2);
        updatedRow.size = selectedItem.sizes.length > 0 ? selectedItem.sizes[0].size_name : '';
        updatedRow.size_variation_id = selectedItem.sizes.length > 0 ? selectedItem.sizes[0].item_variation_id : '';
        updatedRow.client = customer ? customer.given_name+' '+customer.family_name : '';
        updatedRow.cost = (Number(selectedItem.cost / 100)).toFixed(2).toString();
        updatedRow.client_id_purchased = customer ? customer.id : '';
        updatedRow.gender = selectedItem.gender || "";
      } 
      break;
    case 'client':
      updatedRow.client = value;
      updatedRow.client_id_purchased = other_value ? other_value : '';
      break;
    default:
      updatedRow[field] = value;
  }

  return updatedRow;
};
