// contexts/LookUpItemsContext.js
import React, { ReactNode, createContext, useContext, useState } from 'react';

interface LookUpItemsContextType {
    items: any[]; // Adjust the type according to your data structure
    setItems: React.Dispatch<React.SetStateAction<any[]>>;
    selectedStyles: { value: string; label: string; }[];
    setSelectedStyles: React.Dispatch<React.SetStateAction<{ value: string; label: string; }[]>>;
    startDate: string;
    setStartDate: React.Dispatch<React.SetStateAction<string>>;
    endDate: string;
    setEndDate: React.Dispatch<React.SetStateAction<string>>;
    selectInputValue: { value: string; label: string; }[];
    setSelectInputValue: React.Dispatch<React.SetStateAction<{ value: string; label: string; }[]>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    error: Error | null;
    setError: React.Dispatch<React.SetStateAction<Error | null>>;
  }
  
  // Provide initial context value with types
  const initialContextValue: LookUpItemsContextType = {
    selectedStyles: [],
    setSelectedStyles: () => {},
    startDate: '',
    setStartDate: () => {},
    endDate: new Date().toISOString().split('T')[0],
    setEndDate: () => {},
    selectInputValue: [],
    setSelectInputValue: () => {},
    // useFetchItems
    items: [], // Default empty array for items
    setItems: () => {}, // Function that does nothing, just a placeholder
    loading: false, // Default loading state
    setLoading: () => {}, // Placeholder function
    error: null, // Default error state
    setError: () => {}, // Placeholder function
  };

  interface LookUpItemsProviderProps {
    children: ReactNode;
  }
  
const LookUpItemsContext = createContext<LookUpItemsContextType>(initialContextValue);

export const useLookUpItems = () => useContext(LookUpItemsContext);

export const LookUpItemsProvider: React.FC<LookUpItemsProviderProps> = ({ children }) => {
    const [selectedStyles, setSelectedStyles] = useState<{ value: string; label: string; }[]>([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [selectInputValue, setSelectInputValue] = useState<{ value: string; label: string; }[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const value = {
      selectedStyles,
      setSelectedStyles,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      selectInputValue,
      setSelectInputValue,
      items,
      setItems,
      loading,
      setLoading,
      error,
      setError,
    };
  
    return (
      <LookUpItemsContext.Provider value={value}>
        {children}
      </LookUpItemsContext.Provider>
    );
  };
