// CustomerList.tsx

import React from 'react';
import { useSearch } from './hooks/SearchContext';
import { Customer } from './types/customer_interface';
import { useCustomer } from './hooks/useCustomer';
import { fetchCustomerRefId } from '../api/axios_request';

const CustomerList: React.FC = () => {
//   const { items: customers, setSelectedItem: setSelectedCustomer } = useSearch();
  const { searched_items: customers, setItems, setSelectedItem: setSelectedCustomer } = useSearch<Customer>(); // Use the Customer type here
  const { setCustomer, setOtherMembers } = useCustomer(); // Access the customer from the global state

  // console.log("inside CustomerList")

  const selectingCustomer = (e: any, customer: Customer) => {
    e.preventDefault()
    setCustomer(customer)
    setItems([])
    fetchCustomerByRefId(customer.reference_id)
  }

  const fetchCustomerByRefId = async (reference_id: string): Promise<Customer[]> => {
    const response = await fetchCustomerRefId(reference_id);
    // const data = await response.json();
    setOtherMembers(response)
    return response;
  };
  
  return (
    <div> {/* Ensure this div takes up full height and enables scrolling */}
    <ul className="list-none p-0 m-0">
      {Array.isArray(customers) && customers.map((customer) => (
                <li key={customer.id} className="cursor-pointer p-1 hover:bg-gray-100" onClick={(e) => selectingCustomer(e, customer)}>

                {customer.family_name + ", " +  customer.given_name}

        </li>
      ))}
    </ul>
  </div>
  );
};

export default CustomerList;
