import React from 'react';
import { useSearch } from './hooks/SearchContext';
import { Customer } from './types/customer_interface';
import { useCustomer } from './hooks/useCustomer';
import NoCustomerPlaceholder from './NoCustomerPlaceholder';

const CustomerInfo: React.FC = () => {
  const { customer, otherMembers } = useCustomer(); // Access the customer from the global state
  if (!customer) {
    return (
    <div className="text-gray-800">  
      {/* <NoCustomerPlaceholder/> */}
    </div>
  )}
  return (

    <div>
    <div className="mx-0 my-4">
      <h2 className="text-1xl font-bold text-center mb-5">Customer Information</h2>
      <div className="text-gray-800 px4">
        <p><span className="font-semibold">Name:</span> {customer.given_name}, {customer.family_name}</p>
        <p><span className="font-semibold">Email:</span> {customer.email_address}</p>
        <p><span className="font-semibold">Phone:</span> {customer.phone_number}</p>
        <p><span className="font-semibold">Group IDs:</span> {customer.group_ids.join(', ')}</p>
        <p><span className="font-semibold">Reference ID:</span> {customer.reference_id}</p>
      </div>
    </div>

    <div className="my-4">
      <hr className="border-t border-gray-300" />
    </div>

    <div className="my-4">
      <h3 className="text-center text-1xl font-bold mb-3">Members On File</h3>
      {otherMembers && otherMembers.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          {otherMembers.map((member, index) => (
            <div key={index} className="text-left bg-gray-100 rounded-lg shadow">
              <p><span className="font-semibold">Name:</span> {member.given_name}, {member.family_name}</p>
              <p><span className="font-semibold">Email:</span> {member.email_address}</p>
              <p><span className="font-semibold">Phone:</span> {member.phone_number}</p>
              <p><span className="font-semibold">Group IDs:</span> {member.group_ids.join(', ')}</p>
              <p><span className="font-semibold">Reference ID:</span> {member.reference_id}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-700">No other members on file.</p>
      )}
    </div>
  </div>
  );
  
};

export default CustomerInfo;