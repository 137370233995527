// InventoryContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Inventory } from '../types/inventory_interface';
import { fetchItemsByCategory } from '../../api/axios_request';

interface InventoryContextState {
  inventory: Inventory;
  fetchInventory: (style: string) => Promise<void>;
}

const InventoryContext = createContext<InventoryContextState>({
  inventory: {} as Inventory,
  fetchInventory: async () => {},
});

export const useInventory = () => useContext(InventoryContext);

interface InventoryProviderProps {
  children: ReactNode;
}

export const InventoryProvider: React.FC<InventoryProviderProps> = ({ children }) => {
  const [inventory, setInventory] = useState<Inventory>({});

  const fetchInventory = async (style: string) => {
    try {
        const response = await fetchItemsByCategory(style);
        setInventory(prevInventory => {
          // If the brand already exists, concatenate the new items with the existing ones.
          // Otherwise, add a new key with the fetched items.
          const newInventory = { ...prevInventory };
                // Extract the array of ShoeItems for the given style (brand) from the response
          const shoeItems = response[style];
          if (newInventory[style]) {
            // newInventory[style] = [...newInventory[style], ...response];
            newInventory[style] = [...newInventory[style], ...shoeItems];

          } else {
            // newInventory[style] = response;
                    // If it doesn't, add this style with its shoe items to the inventory

            newInventory[style] = shoeItems;

          }
          return newInventory;
        });
      } catch (error) {
        console.error("Failed to fetch items for style: ", style, error);
        // return error
      }
    // return response;
  };

  return (
    <InventoryContext.Provider value={{ inventory, fetchInventory }}>
      {children}
    </InventoryContext.Provider>
  );
};
